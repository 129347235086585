// 配列の配列用　[[],[]]
export const exportCSV = (records:String[][], fileName: string) => {
  if(!records || records.length === 0 ) return;
  let data = records.map((record)=>record.join(',')).join('\r\n');
  let bom  = new Uint8Array([0xEF, 0xBB, 0xBF]);
  let blob = new Blob([bom, data], {type: 'text/csv'});
  let url = (window.URL || window.webkitURL).createObjectURL(blob);
  let link = document.createElement('a');
  link.download = `${fileName}.csv`;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
